import React, { MouseEventHandler } from 'react'
import { IconSquare, IconSquareCheckFilled } from '@tabler/icons-react'
import cx from 'clsx'

interface CheckboxProps {
  isHeader?: boolean
  htmlFor?: string
  labelClassName?: string
  checked?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  className?: string
  disabled?: boolean
  onClick?: MouseEventHandler
  name?: string
  id?: string
}

const Checkbox = React.forwardRef<HTMLInputElement, React.PropsWithChildren<CheckboxProps>>(
  (
    { checked = false, onChange, className, disabled, children, htmlFor, labelClassName, onClick, id, name, ...props },
    ref,
  ) => {
    return (
      <label
        onClick={onClick || ((e) => e.stopPropagation())}
        className={cx('relative flex items-center cursor-pointer', disabled && 'pointer-events-none', className)}
        htmlFor={id || htmlFor}
      >
        <input
          ref={ref}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation()
            if (!disabled && onChange) {
              onChange(e)
            }
          }}
          id={id || htmlFor}
          name={name}
          type="checkbox"
          className="appearance-none absolute inset-0 w-0 h-0"
          checked={checked}
          {...props}
        />
        <div className="relative h-4 w-4">
          {checked ? (
            <IconSquareCheckFilled
              className={cx('absolute inset-0 w-full h-full z-[2] text-brand_primary', disabled && 'text-neutral-300')}
            />
          ) : (
            <IconSquare
              className={cx(
                'absolute inset-0 w-full h-full z-[2] text-gray-300',
                'hover:text-brand_primary',
                disabled && 'text-neutral-300',
              )}
            />
          )}
          <div className={cx('absolute top-0.5 left-0.5 w-3 h-3 z-[1]', disabled ? 'bg-neutral-100' : 'bg-white')} />
        </div>
        {children && <span className={cx('ml-1.5 text-xs', labelClassName)}>{children}</span>}
      </label>
    )
  },
)

export default Checkbox
