import { useMutation, useQueryClient } from '@tanstack/react-query'
import { WorkflowKeys } from '../Keys'

import { useGetOne, useCreate, useGetList, useDelete, useUpdate } from '../request'

import {
  Workflow,
  Category,
  Template,
  WorkflowError,
  WorkflowListElement,
  WorkflowListRequest,
  WorkflowReorderRequest,
  WorkflowValidateRequest,
  ChatGeneratedField,
} from '@models/Workflow'
import api from '@src/api/api'
import { generatePath } from 'react-router-dom'

const PATHS = {
  workflows: '/api/workflows',
  workflowById: '/api/workflows/:id',
  categories: '/api/workflows/categories',
  templates: '/api/workflows/templates',
  validate: '/api/workflows/validate',
  chatGPT: '/api/workflows/generate-template',
}

export const useGetWorkflow = (id?: string | number | null, isForApprovalRequest = false, staleTime = 0) => {
  const params = isForApprovalRequest ? { for_approvaL_request: 1 } : {}
  return useGetOne<Workflow<IsResponse>>(
    [WorkflowKeys.Workflow, id?.toString(), JSON.stringify(params)],
    `${PATHS.workflows}/${id}`,
    {
      query: {
        staleTime,
        enabled: !!id,
      },
      axios: {
        params,
      },
    },
  )
}

export const useGetWorkflowByVersion = (id?: string | number | null, version?: string) => {
  return useGetOne<Workflow<IsResponse>>(
    [WorkflowKeys.Workflow, id, version],
    `${PATHS.workflows}/${id}/versions/${version}`,
    {
      query: {
        cacheTime: Infinity,
        enabled: !!id && !!version,
      },
    },
  )
}

export const useGetWorkflows = <IsFieldsIncluded = false>(params?: WorkflowListRequest) => {
  return useGetList<WorkflowListElement<IsFieldsIncluded>>([WorkflowKeys.Workflows], PATHS.workflows, {
    axios: {
      params,
    },
  })
}

export const useReorderWorkflows = () => {
  return useUpdate<WorkflowReorderRequest>(PATHS.workflows)
}

export const useCreateWorkflow = () => {
  return useCreate<Workflow<IsRequest>, Workflow<IsResponse>, WorkflowError>(PATHS.workflows)
}

export const useUpdateWorkflow = (id?: number) => {
  const cache = useQueryClient()
  return useUpdate<Workflow<IsRequest>, Workflow<IsResponse>, WorkflowError>(
    id ? generatePath(PATHS.workflowById, { id }) : PATHS.workflowById,
    {
      mutation: {
        onSuccess: () => {
          cache.removeQueries([WorkflowKeys.Workflow, id?.toString()], { exact: true })
        },
      },
    },
  )
}

export const useGetWorkflowCategories = () => {
  return useGetList<Category>([WorkflowKeys.Categories], PATHS.categories, {
    query: {
      cacheTime: Infinity,
    },
  })
}

export const useCreateWorkflowCategory = () => {
  const cache = useQueryClient()
  return useCreate<Omit<Category, 'id'>, Category>(PATHS.categories, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([WorkflowKeys.Categories])
      },
    },
  })
}

export const useDeleteWorkflowCategories = () => {
  const cache = useQueryClient()
  return useDelete<Array<number>>(PATHS.categories, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([WorkflowKeys.Categories])
      },
    },
  })
}
export const useGetWorkflowTemplates = () => {
  return useGetList<Template>([WorkflowKeys.Templates], PATHS.templates, {
    query: {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  })
}

export const useCheckWorkflowName = () => {
  return useUpdate<WorkflowValidateRequest>(PATHS.validate)
}

export const useDuplicateWorkflow = (id: number) => {
  const cache = useQueryClient()
  return useCreate(`${PATHS.workflows}/${id}/duplicate`, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([WorkflowKeys.Workflows])
      },
    },
  })
}

export const useDeleteWorkflow = (id?: number) => {
  const path = `${PATHS.workflows}/:id`
  const cache = useQueryClient()
  return useDelete(id ? generatePath(path, { id }) : path, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([WorkflowKeys.Workflows])
      },
    },
  })
}

export const useNotifyRequestors = (id?: number) => {
  return useCreate<{ emails: string[] }>(`${PATHS.workflows}/${id}/notification`)
}

export const useGenerateTemplate = () => {
  return useMutation<
    {
      name: string
      fields: ChatGeneratedField[]
    },
    unknown,
    {
      question: string
      answer: string
    }[]
  >(
    async (
      body: {
        question: string
        answer: string
      }[],
    ) => {
      const res = await api.post<{
        name: string
        fields: ChatGeneratedField[]
      }>(PATHS.chatGPT, body)
      return res.data
    },
  )
}

export { WorkflowKeys as Keys }
