import React from 'react'

import Image from '@components/SuspenseImage'

const NotFound = () => {
  return (
    <a href="/" className="h-screen w-full flex p-3">
      <div className="m-auto max-w-lg">
        <Image src="/404.png" alt="not found" />
      </div>
    </a>
  )
}

export default NotFound
