import { Status, Priority } from '@type/common'
import i18n from '@src/i18n'

import { priorities as Priorities } from '@src/constants'

import {
  ClockIcon,
  CheckCircleIcon,
  CalendarIcon,
  XCircleIcon,
  MinusCircleIcon,
  PencilIcon,
  PauseIcon,
} from '@heroicons/react/outline'

export const statuses: Record<
  Status,
  {
    title: string
    borderColor: string
    bgColor?: string
    textColor: string
    icon: React.FC<Record<string, unknown>>
  }
> = {
  [Status.pending]: {
    title: i18n.t('common:requestStatus.pending'),
    borderColor: '!border-purple-200',
    bgColor: 'bg-purple-50',
    textColor: 'text-brand_primary',
    icon: ClockIcon,
  },
  [Status.approved]: {
    title: i18n.t('common:requestStatus.approved'),
    borderColor: '!border-emerald-200',
    bgColor: 'bg-emerald-50',
    textColor: 'text-emerald-700',
    icon: CheckCircleIcon,
  },
  [Status.rejected]: {
    title: i18n.t('common:requestStatus.rejected'),
    borderColor: '!border-red-200',
    bgColor: 'bg-red-50',
    textColor: 'text-red-700',
    icon: XCircleIcon,
  },
  [Status.canceled]: {
    title: i18n.t('common:requestStatus.canceled'),
    borderColor: '!border-gray-200',
    bgColor: 'bg-gray-50',
    textColor: 'text-gray-700',
    icon: XCircleIcon,
  },
  [Status.deferred]: {
    title: i18n.t('common:requestStatus.deferred'),
    borderColor: '!border-indigo-100',
    bgColor: 'bg-indigo-50',
    textColor: 'text-indigo-800',
    icon: PauseIcon,
  },
  [Status.scheduled]: {
    title: i18n.t('common:requestStatus.scheduled'),
    borderColor: '!border-sky-200',
    bgColor: 'bg-sky-50',
    textColor: 'text-sky-700',
    icon: CalendarIcon,
  },
  [Status.change_requested]: {
    title: i18n.t('common:requestStatus.change_requested'),
    borderColor: '!border-amber-200',
    bgColor: 'bg-amber-50',
    textColor: 'text-amber-700',
    icon: PencilIcon,
  },
  [Status.not_required]: {
    title: i18n.t('common:requestStatus.notRequired'),
    borderColor: '!border-gray-200',
    bgColor: 'bg-gray-50',
    textColor: 'text-gray-700',
    icon: MinusCircleIcon,
  },
}

export const POPUP_STATE = {
  CONFIRM: 'CONFIRM',
  SUCCESS: 'success',
  NOT_ACTIVE: 'not_active',
}

export const priorities: Partial<
  Record<
    Priority,
    {
      title: string
      color: string
    }
  >
> = {
  [Priority.low]: {
    title: Priorities.low.label,
    color: 'text-emerald-700',
  },
  [Priority.medium]: {
    title: Priorities.medium.label,
    color: 'text-sky-700',
  },
  [Priority.high]: {
    title: Priorities.high.label,
    color: 'text-amber-700',
  },

  [Priority.critical]: {
    title: Priorities.critical.label,
    color: 'text-red-700',
  },
}
