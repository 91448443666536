import { useCallback, useMemo, useState } from 'react'

import debounce from '@helpers/debounce'

type FilterFunction<T extends object> = (record: T) => boolean
const search = <T extends object>(records: T[], fn: FilterFunction<T>) => {
  return records.filter(fn)
}

export const useGetSearchHelpers = (defaultSearch = '', setValue?: (value: string) => void) => {
  const [searchValue, setSearchString] = useState(defaultSearch)
  const [inputValue, setInputValue] = useState(defaultSearch)

  const setSearchStringDebounced = debounce(setSearchString)
  const setSearchOutsideStringDebounced = setValue ? debounce(setValue) : null

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setSearchStringDebounced(e.target.value)
    setSearchOutsideStringDebounced?.(e.target.value)
    setInputValue(e.target.value)
  }, [])

  return useMemo(
    () => ({
      search,
      searchValue,
      inputValue,
      handleSearchChange,
    }),
    [searchValue, inputValue],
  )
}

export default useGetSearchHelpers
