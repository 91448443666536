import React from 'react'
import cx from 'clsx'
import { Row } from 'react-table'
import { Draggable } from 'react-beautiful-dnd'
import { ViewListIcon } from '@heroicons/react/outline'

export interface RowProps<TRecord extends object> {
  row: Row<TRecord>
  index: number
  onRowClick?: (record: TRecord) => void
  className?: string
  tdClassName?: string
  isDnD?: boolean
}

export const TableRow = <
  TRecord extends object & {
    id?: number | string
    customWidth?: number
  },
>({
  row,
  index,
  onRowClick,
  className,
  tdClassName,
  isDnD = false,
}: RowProps<TRecord>) => {
  const getRow = (
    ref?: React.LegacyRef<HTMLTableRowElement>,
    additionalProps?: object,
    dragHandleProps?: object | null,
  ) => {
    const props = row.getRowProps()

    const rowCmp = (
      <tr
        {...props}
        ref={ref}
        onClick={() => onRowClick?.(row.original)}
        className={cx(className)}
        {...additionalProps}
      >
        {isDnD && (
          <td
            className={cx(tdClassName, 'text-gray-300 w-6 pl-4 pr-1 group-hover:text-gray-500')}
            key={index + '_cell_dnd'}
            {...dragHandleProps}
          >
            <ViewListIcon className={cx('h-4 w-4')} />
          </td>
        )}
        {row.cells.map((cell) => {
          const column = cell.column

          const { key: cellKey, ...props } = cell.getCellProps({
            style: { maxWidth: cell.column.maxWidth },
          })

          const key = row.original.id ? `${row.id}.${column.id}` : cellKey

          return (
            <td key={key} {...props} className={cx(tdClassName, !isDnD && 'first:pl-[52px]')}>
              {cell.render('Cell')}
            </td>
          )
        })}
      </tr>
    )

    return rowCmp
  }

  if (isDnD) {
    return (
      <Draggable draggableId={`${row.original.id}`} index={index}>
        {(provided, snapshot) => {
          const cmp = getRow(
            provided.innerRef,
            {
              ...provided.draggableProps,
              style: {
                ...provided.draggableProps.style,
                ...(snapshot.isDragging
                  ? {
                      background: '#D5E1FF',
                      display: 'flex',
                      borderRadius: '25px',
                      alignItems: 'center',
                    }
                  : {}),
              },
            },
            provided.dragHandleProps,
          )

          return cmp
        }}
      </Draggable>
    )
  }

  return getRow()
}

export default React.memo(TableRow)
