import React from 'react'
import OnpageLoading from 'react-fullscreen-loading'
import styles from './index.module.css'
import { useTranslation } from 'react-i18next'

export interface LoadingProps {
  loading: boolean
  redirectTo?: string
  redirectURL?: string
}

export const Loading: React.FC<LoadingProps> = ({ loading = false, redirectTo, redirectURL }) => {
  const { t } = useTranslation('common', { keyPrefix: 'auth' })
  return redirectTo ? (
    <>
      <div className={styles.redirectBg}>
        <div className="absolute box-content px-4 max-w-xs max-h-sm transform flex flex-col justify-center items-center gap-9">
          <img src={`/${redirectTo.toLowerCase()}-redirect.svg`} alt={`Redirecting to ${redirectTo}...`} />
          <a
            href={redirectURL}
            className="w-fit bg-white px-4 py-2 rounded-xl font-bold text-brand_primary whitespace-break-spaces	flex items-center"
          >
            {t('redirectingTo')} {redirectTo}
            {'  '}
            <span className="spinner-border" />
          </a>
        </div>
      </div>
    </>
  ) : (
    <OnpageLoading
      className="fixed top-0 left-0"
      loading={loading}
      background="rgba(236, 240, 241, 0.5)"
      loaderColor="#662DEF"
    />
  )
}

export default Loading
