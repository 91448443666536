import React from 'react'

type ImgCache = {
  __cache: { [key: string]: boolean | Promise<boolean | void> }
  read: (src: string) => boolean | Promise<boolean | void>
}

const imgCache: ImgCache = {
  __cache: {},
  read(src: string) {
    if (!this.__cache[src]) {
      this.__cache[src] = new Promise((resolve) => {
        const img = new Image()
        img.onload = () => {
          this.__cache[src] = true
          resolve(this.__cache[src])
        }
        img.src = src
      }).then(() => {
        this.__cache[src] = true
      })
    }
    if (this.__cache[src] instanceof Promise) {
      throw this.__cache[src]
    }
    return this.__cache[src]
  },
}

export interface SuspenseImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string
}

export const SuspenseImage: React.FC<SuspenseImageProps> = ({ src, ...rest }) => {
  imgCache.read(src)
  return <img src={src} {...rest} />
}

export default SuspenseImage
