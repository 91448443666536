import React from 'react'
import cx from 'clsx'

import { checkIsLink } from '@helpers/utils'

export interface TextProps {
  text: string
  className?: string
}

export const Text: React.FC<TextProps> = ({ text, className }) => {
  const formatedText = `${text}`.split('\n').map((str: string, i: number) => {
    const line = str.split(' ').map((el, index, arr) => {
      const isLast = arr.length === index + 1
      const isLink = checkIsLink(el)
      const isBold = el.startsWith('*') && el.endsWith('*')

      let word = el

      if (isBold) {
        word = (word as string).slice(1, word.length - 1)
      }

      word = `${word}${isLast ? '' : ' '}`

      if (isLink) {
        return (
          <a
            key={word}
            target="_blank"
            rel="noopener noreferrer nofollow"
            href={el as string}
            className="text-blue-600 break-all"
          >
            {word}
          </a>
        )
      }
      if (isBold) {
        return <b key={word}>{word}</b>
      }

      return word
    })
    return <p key={i}>{str ? line : <>&nbsp;</>}</p>
  })
  return <div className={cx(className)}>{formatedText}</div>
}

export default Text
