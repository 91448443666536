import React from 'react'
import cx from 'clsx'

import styles from './index.module.css'

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Card: React.FC<React.PropsWithChildren<CardProps>> = ({ children, className, ...props }) => {
  return (
    <div className={cx(styles.card, className)} {...props}>
      {children}
    </div>
  )
}

export default Card
