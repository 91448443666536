import React from 'react'
import { HeaderGroup } from 'react-table'
import cx from 'clsx'

import SortableHeader from '../SortableHeader'

import { SortOrder } from '@type/common'

export interface TableHeaderProps<TRecord extends object> {
  headerGroup: HeaderGroup<TRecord>
  className?: string
  thClassName?: string
  thClassNames?: { [key: string]: string }
  isDnd?: boolean
}

export const TableHeader = <TRecord extends object>({
  headerGroup,
  className,
  thClassName,
  thClassNames,
  isDnd = false,
}: TableHeaderProps<TRecord>) => {
  const { key, ...props } = headerGroup.getHeaderGroupProps()
  const dndCmp = isDnd && <th className={cx(thClassName)} />

  return (
    <tr key={key} {...props} className={cx(className)}>
      {dndCmp}
      {headerGroup.headers.map((column) => {
        const { key, ...props } = column.getHeaderProps({
          style: { maxWidth: column.maxWidth },
        })
        const sortProps = column.getSortByToggleProps()
        const header = !column.defaultCanSort ? (
          column.render('Header')
        ) : (
          <SortableHeader
            onClick={sortProps.onClick}
            order={column.isSorted ? (column.isSortedDesc ? SortOrder.desc : SortOrder.asc) : undefined}
            className={(column as unknown as { className: string }).className}
          >
            {column.render('Header')}
          </SortableHeader>
        )
        return (
          <th key={key} {...props} className={thClassName}>
            <div className={thClassNames && thClassNames[column.id]}> {header}</div>
          </th>
        )
      })}
    </tr>
  )
}

export default React.memo(TableHeader)
