import React, { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import cx from 'clsx'

import Button, { ButtonProps } from '@components/Button'

import styles from './index.module.css'
import { IconChevronLeft } from '@tabler/icons-react'
import LinkButton from '../LinkButton'

export interface BackButtonProps extends ButtonProps {
  to?: string
  label?: string
}

const BackButton: React.FC<BackButtonProps> = ({ to, onClick, className, label = '', ...props }) => {
  const navigate = useNavigate()
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault()
      if (onClick) {
        onClick?.(e)
      } else {
        navigate(-1)
      }
    },
    [onClick],
  )
  return to ? (
    <LinkButton
      to={to}
      type="outlined"
      buttonType="button"
      className={cx(styles.button, label ? 'px-4' : 'p-0 m-0', className)}
      {...props}
    >
      <IconChevronLeft className={cx('h-4 w-4', label && 'mr-1')} />
      {label}
    </LinkButton>
  ) : (
    <Button
      onClick={handleClick}
      type="outlined"
      buttonType="button"
      className={cx(styles.button, label ? 'px-4' : 'p-0 m-0', className)}
      {...props}
    >
      <IconChevronLeft className={cx('h-4 w-4', label && 'mr-1')} />
      {label}
    </Button>
  )
}

export default BackButton
