import { QueryFunctionContext, useInfiniteQuery, useMutation, useQueries, useQueryClient } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { generatePath } from 'react-router-dom'

import api from '../../api'
import { AccountingKeys, RequestsKeys, OrgStructureKeys } from '../Keys'

import { useGetOne, useCreate, usePatch, useGetList } from '../request'
import { sendEvent } from '@helpers/hooks/useAmplitude'

import {
  ApprovalRequestList,
  ApprovalRequest,
  Filter,
  ExportFilter,
  FiltersList,
  RequestPayload,
  ApproveRejectRequest,
  ApproveRejectResponse,
  RequestStepUpdateRequest,
  Comment,
  BulkApproveRejectRequest,
  ApprovalRequestVersionData,
  CommentRequest,
} from '@models/Request'

export { RequestsKeys }

const PATHS = {
  requests: '/approval-requests/',
  sendNow: '/approval-requests/:id/send',
  bulk: '/approval-requests/bulk',
  filters: '/approval-requests/filters',
  export: '/approval-requests/export',
  comments: '/approval-requests/:id/comments',
  remind: '/approval-requests/:id/remind',
}

export const useGetRequests = (filter?: Partial<Filter>, enabled = true) => {
  return useGetOne<ApprovalRequestList>([RequestsKeys.Requests], PATHS.requests, {
    query: {
      enabled,
    },
    axios: {
      params: { ...(filter || {}) },
    },
  })
}
export const useInfiniteGetRequestsByStatus = (filter?: Filter) => {
  const onLoadMore = async (ctx: QueryFunctionContext) => {
    const { data }: AxiosResponse = await api.get(PATHS.requests, {
      params: {
        ...filter,
        page: ctx.pageParam || 1,
      },
    })
    return { rows: data?.results, count: data.count }
  }

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
    [RequestsKeys.Requests, filter?.status],
    (ctx) => onLoadMore(ctx),
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.count <= (filter?.size || 0) * allPages.length ? null : allPages.length
      },
    },
  )
  return { data, isFetchingNextPage, fetchNextPage, hasNextPage, refetch }
}

export const useGetRequest = (id?: number | string) => {
  return useGetOne<ApprovalRequest>([RequestsKeys.Request, +(id || 0)], `${PATHS.requests}${id}`, {
    query: {
      enabled: !!id,
    },
  })
}

export const useGetRequestsByIds = (ids?: Array<number>) => {
  return useQueries({
    queries:
      ids?.map((id) => {
        return {
          queryKey: [RequestsKeys.Request, +(id || 0)],
          staleTime: Infinity,
          queryFn: async () => (await api.get<ApprovalRequest>(`${PATHS.requests}${id}`)).data,
        }
      }) || [],
  })
}

export const useGetRequestData = (id?: number | string, versionId?: number | string) => {
  return useGetOne<ApprovalRequestVersionData>(
    [RequestsKeys.Request, +(id || 0), +(versionId || 0)],
    `${PATHS.requests}${id}/data/${versionId}`,
    {
      query: {
        enabled: !!id && !!versionId,
        staleTime: Infinity,
      },
    },
  )
}

// for one time fetching reasons
export const useFetchRequest = () => {
  return useMutation<ApprovalRequest, unknown, number | string>(async (id: number | string) => {
    const res = await api.get<ApprovalRequest>(`${PATHS.requests}${id}`)
    return res.data
  })
}

export const useValidateRequest = (id?: number | string, uuid?: string) => {
  return useGetOne<unknown>([RequestsKeys.RequestValidation, uuid], `${PATHS.requests}${id}/validate/${uuid}`, {
    query: {
      initialData: {},
      enabled: !!id && !!uuid,
    },
  })
}

export const useGetRequestsFilters = () => {
  return useGetOne<FiltersList>([RequestsKeys.Filters], PATHS.filters, {
    query: {
      suspense: true,
    },
  })
}

export const useApproveReject = () => {
  const cache = useQueryClient()

  return useMutation<ApproveRejectResponse, AxiosError<ApproveRejectResponse>, ApproveRejectRequest>(
    async ({ status, id, comment, uuid, signature }: ApproveRejectRequest) => {
      const path = `${PATHS.requests}${uuid ? 'p/' : ''}${uuid || id}/${status}`
      const res = await api.patch<ApproveRejectResponse>(path, { comment: comment || null, signature })
      sendEvent(`${status}_request`)
      return res.data
    },
    {
      mutationKey: [RequestsKeys.ApproveReject],
      onSuccess: (res) => {
        cache.invalidateQueries([RequestsKeys.Request, +res.id])
        cache.invalidateQueries([RequestsKeys.Requests])
        cache.invalidateQueries([OrgStructureKeys.OrgStructure])
        cache.removeQueries({ queryKey: [AccountingKeys.Contacts], type: 'inactive' })
      },
    },
  )
}

export const useBulkApproveReject = () => {
  const cache = useQueryClient()
  return useMutation<ApproveRejectResponse[], AxiosError<BulkApproveRejectRequest>, BulkApproveRejectRequest>(
    async ({ ids, status }) => {
      const path = `${PATHS.bulk}/${status}`
      const res = await api.patch(path, { ids })
      sendEvent(`bulk_${status}_request`)

      return res.data
    },
    {
      mutationKey: [RequestsKeys.ApproveReject],
      onSuccess: () => {
        cache.invalidateQueries([RequestsKeys.Requests])
        cache.removeQueries({ queryKey: [AccountingKeys.Contacts], type: 'inactive' })
      },
    },
  )
}

export const usePublicComment = (uuid: string) => {
  return useCreate<{ text: string }>(`${PATHS.requests}p/${uuid}/comment`)
}

export const useGetPublicInfo = (uuid: string) => {
  return useGetOne<
    {
      id: number
      status: string
      actions: { reject: string; approve: string }
    },
    {
      id: number
      status: string
    }
  >([RequestsKeys.PublicRequest], `${PATHS.requests}p/${uuid}`)
}

export const useCreateRequest = () => {
  return useCreate<RequestPayload, ApprovalRequest>(PATHS.requests)
}

export const useUpdateRequest = (requestId?: string | number) => {
  const cache = useQueryClient()
  return usePatch<RequestPayload, ApprovalRequest>(`${PATHS.requests}${requestId}`, {
    mutation: {
      onSuccess: () => {
        sendEvent('request_updated')
        cache.removeQueries([RequestsKeys.Request, +(requestId || 0)])
      },
    },
  })
}
export const useSendScheduledRequestNow = (requestId: string | number) => {
  const cache = useQueryClient()
  return usePatch(PATHS.sendNow.replace(':id', requestId?.toString()), {
    mutation: {
      onSuccess: () => {
        sendEvent('send_scheduled_request_now')
        cache.invalidateQueries([RequestsKeys.Request, +requestId])
      },
    },
  })
}

export const useUpdateRequestSteps = (id: string | number) => {
  const cache = useQueryClient()
  return usePatch<RequestStepUpdateRequest>(`${PATHS.requests}${id}/approvers`, {
    mutation: {
      onSuccess: () => {
        sendEvent('request_steps_updated')
        cache.invalidateQueries([RequestsKeys.Request, +id])
      },
    },
  })
}

export const useExport = (params: ExportFilter) => {
  return useMutation(
    async () => {
      const response = await api.get(PATHS.export, {
        params: Object.keys(params).reduce(
          (acc, key) =>
            params[key as keyof ExportFilter] ? { ...acc, [key]: params[key as keyof ExportFilter] } : acc,
          {},
        ),
        responseType: 'blob',
      })

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `approval_requests_${new Date().toISOString()}.${params.type}`)
      document.body.appendChild(link)
      link.click()
      sendEvent('export_requests')

      return null
    },
    {
      mutationKey: [RequestsKeys.Export],
    },
  )
}

export const useSendComment = (id?: string | number) => {
  const cache = useQueryClient()
  return useCreate<CommentRequest>(generatePath(PATHS.comments, { id }), {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([RequestsKeys.RequestComments, id])
      },
    },
    query: {
      enabled: !!id,
    },
  })
}

export const useGetComments = (id?: string | number) => {
  return useGetList<Comment>([RequestsKeys.RequestComments, id], generatePath(PATHS.comments, { id }), {
    query: {
      enabled: !!id,
    },
  })
}

export const useRemindApprovers = (id: string | number) => {
  const cache = useQueryClient()
  return usePatch(generatePath(PATHS.remind, { id }), {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([RequestsKeys.Request, +id])
      },
    },
  })
}
