import { useGetMyAccount } from '@queries/Account'

import { Role } from '@type/common'

export const useIsAdmin = (): boolean => {
  const { data: user } = useGetMyAccount()
  return user?.role === Role.admin
}

export default useIsAdmin
