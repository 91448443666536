export enum Integration {
  zapier = 'zapier',
  softledger = 'softledger',
  xero = 'xero',
  netsuite = 'netsuite',
  quickbooks = 'quickbooks',
}

export enum SoftledgerCustomFieldName {
  custom1 = 'custom1',
  custom2 = 'custom2',
  custom3 = 'custom3',
}
