import React, { useMemo } from 'react'
import cx from 'clsx'

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'

import styles from './index.module.css'

export interface PagingProps {
  pageIndex: number
  pageSize: number
  rowCount: number
  pageCount: number
  totalRowCount: number
  // nextPage: () => void
  // previousPage: () => void
  goToPage: (pageIndex: number) => void
}

export const Paging: React.FC<PagingProps> = ({
  pageIndex: rawPageIndex,
  pageSize,
  rowCount,
  pageCount,
  totalRowCount,
  // nextPage,
  // previousPage,
  goToPage,
}) => {
  const pageIndex = rawPageIndex + 1
  const { from, to, totalPages } = useMemo(() => {
    return {
      from: rowCount ? (pageIndex - 1) * pageSize + 1 : 0,
      to: (pageIndex - 1) * pageSize + pageSize,
      totalPages: pageCount,
    }
  }, [pageIndex, pageSize, totalRowCount, rowCount])

  const pages = useMemo(() => {
    if (totalPages <= 5) {
      return Array.from({ length: totalPages }).map((_, i) => (
        <button
          className={cx(styles.pageButton, i + 1 === pageIndex && styles.active)}
          key={i}
          name={`${i + 1}`}
          onClick={() => goToPage(i)}
        >
          {i + 1}
        </button>
      ))
    }

    const isStart = pageIndex < 4
    const isEnd = totalPages - pageIndex < 3
    const isBetween = !isStart && !isEnd
    const list = Array.from({ length: totalPages })
      .map((_, i) => i + 1)
      .filter((i) => {
        if (isStart) {
          return i < 4
        }

        if (isEnd) {
          return i > totalPages - 3
        }

        return [pageIndex - 1, pageIndex, pageIndex + 1].includes(i)
      })

    return (
      <>
        {isStart && (
          <>
            {list.map((i) => (
              <button
                className={cx(styles.pageButton, i === pageIndex && styles.active)}
                key={i}
                name={`${i}`}
                onClick={() => goToPage(i - 1)}
              >
                {i}
              </button>
            ))}
            <span className={cx(styles.pageButton, 'pointer-events-none')}>...</span>
            <button className={styles.pageButton} name={`${totalPages}`} onClick={() => goToPage(totalPages - 1)}>
              {totalPages}
            </button>
          </>
        )}
        {isEnd && (
          <>
            <button className={styles.pageButton} name={`${1}`} onClick={() => goToPage(0)}>
              {1}
            </button>
            <span className={cx(styles.pageButton, 'pointer-events-none')}>...</span>
            {list.map((i) => (
              <button
                className={cx(styles.pageButton, i === pageIndex && styles.active)}
                key={i}
                name={`${i}`}
                onClick={() => goToPage(i - 1)}
              >
                {i}
              </button>
            ))}
          </>
        )}
        {isBetween && (
          <>
            <button className={styles.pageButton} name={`${1}`} onClick={() => goToPage(0)}>
              {1}
            </button>
            <span className={cx(styles.pageButton, 'pointer-events-none')}>...</span>
            {list.map((i) => (
              <button
                className={cx(styles.pageButton, i === pageIndex && styles.active)}
                key={i}
                name={`${i}`}
                onClick={() => goToPage(i - 1)}
              >
                {i}
              </button>
            ))}
            <span className={cx(styles.pageButton, 'pointer-events-none')}>...</span>
            <button className={styles.pageButton} name={`${totalPages}`} onClick={() => goToPage(totalPages - 1)}>
              {totalPages}
            </button>
          </>
        )}
      </>
    )
  }, [totalPages, pageIndex])

  const nextPage = () => {
    goToPage(rawPageIndex + 1)
  }

  const previousPage = () => {
    goToPage(rawPageIndex - 1)
  }

  return (
    <div className="py-3 px-6 flex justify-center w-full border-t border-purple-200">
      <div className="flex-1 flex justify-between md:hidden items-center">
        <button
          className={cx(styles.navigationArrowButton, pageIndex === 1 && 'pointer-events-none')}
          name={`${pageIndex - 1}`}
          onClick={previousPage}
        >
          <ChevronLeftIcon className="h-6 w-6" />
        </button>
        <div>
          <p className="md:hidden text-sm text-gray-700 mx-2">
            Showing
            <span className="font-medium"> {from} </span>
            to
            <span className="font-medium"> {to > totalRowCount ? totalRowCount : to} </span>
            of
            <span className="font-medium"> {totalRowCount} </span>
            results
          </p>
        </div>
        <button
          className={cx(styles.navigationArrowButton, pageIndex === totalPages && 'pointer-events-none')}
          name={`${pageIndex + 1}`}
          onClick={nextPage}
        >
          <ChevronRightIcon className="h-6 w-6" />
        </button>
      </div>
      <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
        <div>
          <p className="hidden lg:block text-sm text-gray-700">
            Showing
            <span className="font-medium"> {from} </span>
            to
            <span className="font-medium"> {to > totalRowCount ? totalRowCount : to} </span>
            of
            <span className="font-medium"> {totalRowCount} </span>
            results
          </p>
        </div>
        <div>
          <nav className={styles.pager} aria-label="Pagination">
            <button
              className={cx(styles.pageButton, 'rounded-l-md', pageIndex === 1 && 'pointer-events-none')}
              name={`${pageIndex - 1}`}
              onClick={previousPage}
            >
              <ChevronLeftIcon className="h-4 w-4" />
            </button>
            {pages}
            <button
              className={cx(
                styles.pageButton,
                'rounded-r-md border-r-0',
                pageIndex === totalPages && 'pointer-events-none',
              )}
              name={`${pageIndex + 1}`}
              onClick={nextPage}
            >
              <ChevronRightIcon className="h-4 w-4" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Paging
