import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import cx from 'clsx'

export interface CollapseProps {
  expanded?: boolean
  className?: string
}

export const Collapse: FC<PropsWithChildren<CollapseProps>> = ({ children, className, expanded = false }) => {
  const ref = useRef<HTMLDivElement>(null)

  const [maxHeight, setMaxHeight] = useState<string | number>(0)

  useEffect(() => {
    if (ref.current && expanded) {
      const resizeObserver = new ResizeObserver(() => {
        const scrollHeight = ref.current?.scrollHeight || 0
        setMaxHeight(`${scrollHeight + 20}px`)
      })

      resizeObserver.observe(ref.current)

      return () => {
        resizeObserver.disconnect()
      }
    } else {
      setMaxHeight(0)
    }
  }, [expanded])

  return (
    <div
      className={cx(
        'transition-max-height ease-[cubic-bezier(0.25,0.8,0.25,1)] duration-[450ms]',
        { 'overflow-none': expanded, 'overflow-hidden': !expanded },
        className,
      )}
      style={{ maxHeight }}
    >
      <div ref={ref}>{children}</div>
    </div>
  )
}

export default React.memo(Collapse)
